import { createEnv } from "@t3-oss/env-nextjs";
import { ZodError } from "zod";
import { getEnvOptions } from "./env-options";

let env: ReturnType<typeof createEnv>;

try {
  env = createEnv(getEnvOptions());
} catch (error) {
  if (error instanceof ZodError) {
    // Use Zod's formatting methods to see all issues
    console.error("Detailed environment variable errors:", error.flatten());
  }
  // Re-throw so tests/builds still fail
  throw error;
}

export { env };
